'use strict';

Gri.module({
  name: 'search-alpha',
  ieVersion: null,
  $el: $('.search-alpha'),
  container: '.search-alpha',
  fn: function () {

    $('.search-alpha input[type="text"]').keyup(function(e){
      if(e.keyCode == 13){
        $('.search-alpha input[type="submit"]').trigger("click");
      }
    });

  }
});
